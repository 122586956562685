//import  router from '../router/index.js';
export const common = {
    handleResponse,
  };
  
  async function handleResponse(response) {
    try {
      return await response.text().then((text) => {
        if (!response.ok) {
          // redirect error
          //return Promise.reject(text);
          throw new Error("Bad response", {
            cause: { response },
          });
        } else {
          const data = JSON.parse(text);
          return data;
          // handle status != OK, when respone success
          // if (data.statusCode != 200) {
          //   //return Promise.reject(data.message);
          //   return data.message;
          // } else {
          //   //return Promise.resolve(data);
          //   return data;
          // }
        }
      });
    } catch (error) {
      switch (error.cause.response?.status){
        case 400: break; // handle 400
        case 401: break; // handle 401
        case 404: break; // handle 404
        case 500: break; // handle 500
      }
      return { statusCode: 400 , message: error };
    }
  }
  
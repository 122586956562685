import config from '../config/prod.env';
import {header} from '../helper/header';

import {common} from '../helper/common';
import constant from '../constant/constant'

async function getTokens_NoLogin() {
    let username = constant.USERNAME_NOLOGIN;
    let password = constant.PASSWORD_NOLOGIN;
    const requestOptions = {
        method: 'POST',
        headers: header.authHeaderNoToken(),
        body: JSON.stringify(
            {username, password}
        )
    }
    return await fetch(`${config.API_URL}/NguoiDung/SignIn`, requestOptions).then(response => common.handleResponse(response)).then(res => {
        // login thành công nếu có một token jwt trong response
        if (res.data.accessToken) { // lưu dữ liệu user và token jwt vào local storage để giữ user được log in trong page
            localStorage.setItem('noLoginTokens', JSON.stringify(res.data));
        }
        return(res.data);
    });
}
async function refreshTokens_NoLogin(accessToken,refreshToken) {
    const requestOptions = {
        method: 'POST',
        headers: header.authHeaderNoToken(),
        body: JSON.stringify(
            {accessToken,refreshToken}
        )
    }
    return await fetch(`${config.API_URL}/NguoiDung/RegenerateNewToken`, requestOptions).then(response => common.handleResponse(response)).then(res => {
        // login thành công nếu có một token jwt trong response
        if (res.data.accessToken) { // lưu dữ liệu user và token jwt vào local storage để giữ user được log in trong page
            localStorage.setItem('noLoginTokens', JSON.stringify(res.data));
        }
        return(res.data);
    });
}


export const authenticationService = {
    getTokens_NoLogin,
    refreshTokens_NoLogin,

};


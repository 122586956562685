import { userloginModel } from '@/models/user/user_login.model';
import router from '@/router/index.js';
import {userService} from '@/services/user.services';

const actions = {
    async register({
        dispatch
    }, {user}) {
        // dispatch('alert/success','Đã đến Module',{root:true})
        // return true;
        if (String(user.password).trim() != String(user.passwordConfirm).trim()) {
            dispatch('alert/error', 'Mật khẩu xác nhận không giống mật khẩu', {root: true})
            // dispatch("alert/error", error, { root: true });
            return false;
        }
        if (String(user.sdt).trim() != String(user.sdtConfirmed).trim()) {
            dispatch('alert/error', 'Số điện thoại xác nhận không giống số điện thoại', {root: true})
            // dispatch("alert/error", error, { root: true });
            return false;
        }


        await userService.register(user).then((data) => {
            //commit("registerSuccess", user);
            if(data.statusCode == 200)
            {
                router.push("/");
                setTimeout(() => { 
                    // hiển thị message thành công sau redirect sang trang
                    dispatch("alert/success", data.message, {root: true});
                });                
            }
            else{
                setTimeout(() => { 
                    // hiển thị message thành công sau redirect sang trang
                    dispatch("alert/error", data.message, {root: true});
                });    
            }

        }, (error) => {
            //commit("registerFailure", error);
            //dispatch("alert/error", error, {root: true});
            dispatch("alert/error", "Có lỗi xảy ra trong quá trình thực hiện. Vui lòng thử lại sau!", {root: true});
        });


    },                  

    async login({dispatch }, {user}) {
       return await userService.login(user).then((data) => {
            //commit("registerSuccess", user);
            if(data.statusCode == 200)
            {
                //router.push("/");   
                setTimeout(() => { 
                    dispatch("alert/success", data.message, {root: true}); 
                    
                });  

                return true;
            }
            else{
                setTimeout(() => { 
                    dispatch("alert/error", data.message, {root: true});
                    
                });    
                return false;
            }

        }, (error) => {
            dispatch("alert/error", "Có lỗi xảy ra trong quá trình thực hiện. Vui lòng thử lại sau!", {root: true});
            return false;
        });


    },

    async logout() {
        localStorage.removeItem('userTokens');
    },

    async checkLogin(){
        let user = JSON.parse(localStorage.getItem('userTokens'));
        if (user && user.accessToken && (user.expiresTime * 1000) > Date.now()) {
            return true;
        } 
        // else if( user && user.accessToken && (user.expiresTime * 1000 + 3600) > Date.now())
        // {
        //     let results = await userService.refreshTokens_user();
        //     return results;
        // }
        else {
            let rememberMe = JSON.parse(localStorage.getItem('rememberMe'));
            if(rememberMe == true){
                let userLogin = userloginModel.newModel();
                userLogin.userName = localStorage.getItem('userName');
                userLogin.password = localStorage.getItem('password');
                let results = await userService.reLogin(userLogin);
                return results;
            }
            else {
                localStorage.removeItem('userName');
                localStorage.removeItem('password');
                return false;
            }
        }
    },

    async getDisplayUserName(){
        return localStorage.getItem('userName');
    }
};
export const user = {
    namespaced: true,
    actions

};

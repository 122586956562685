<template>
    <div class="wrapper">
      <TheNavBar></TheNavBar>

      <!-- Content Wrapper. Contains page content -->
      <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
          <div class="container">
            <!-- <div class="row mb-2">
                            <div class="col-sm-6">
                                <h1 class="m-0"> Top Navigation <small>Example 3.0</small></h1>
                            </div>
                            <div class="col-sm-6">
                                <ol class="breadcrumb float-sm-right">
                                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                                    <li class="breadcrumb-item"><a href="#">Layout</a></li>
                                    <li class="breadcrumb-item active">Top Navigation</li>
                                </ol>
                            </div>
                        </div> -->
          </div>
        </div>
        <!-- /.content-header -->

        <!-- Main content -->
        <div class="content">
          <div class="card container">
            <div class="card-header">
              <h3 class="card-title">CAMERA</h3>
            </div>
            <!-- /.card-header -->
            <div class="card-body table-responsive p-0">
                <video ref='video' height="600" id="video" controls></video>
            </div>
            <!-- /.card-body -->
          </div>
        </div>
        <!-- /.content -->
      </div>
      <!-- /.content-wrapper -->

      <!-- Control Sidebar -->
      <aside class="control-sidebar control-sidebar-dark">
        <!-- Control sidebar content goes here -->
      </aside>
      <!-- /.control-sidebar -->

      <!-- Main Footer -->
      <footer class="main-footer">
        <!-- To the right -->
        <!-- <div class="float-right d-none d-sm-inline">
                    Anything you want
                </div> -->
        <!-- Default to the left -->
        <!-- <strong>Copyright &copy; 2014-2021 <a href="https://adminlte.io">AdminLTE.io</a>.</strong> All rights
                reserved. -->
      </footer>
    </div>
    <!-- ./wrapper -->
    <!-- <video ref="video" width="100%" height="640" controls autoplay="" muted="muted"></video> -->
</template>


<script>
    import Hls from 'hls.js';
    //--View--
    import TheNavBar from "../dashboard/TheNavBar.vue"
    export default{
        name: 'TheVideoPage',
        components:{
            TheNavBar
        },
        mounted() {
            let video = this.$refs.video;
            if (Hls.isSupported()) {
                var hls = new Hls({
                debug: true,
                });
                hls.loadSource('https://vms.dongthap.gov.vn:80/streams/63a570ae92b4550012bde46d/stream/63a570ae92b4550012bde46d.m3u8');
                hls.attachMedia(video);
                hls.on(Hls.Events.MEDIA_ATTACHED, function () {
                video.muted = true;
                video.play();
                });
            }
            // hls.js is not supported on platforms that do not have Media Source Extensions (MSE) enabled.
            // When the browser has built-in HLS support (check using `canPlayType`), we can provide an HLS manifest (i.e. .m3u8 URL) directly to the video element through the `src` property.
            // This is using the built-in support of the plain video element, without using hls.js.
            else if (video.canPlayType('application/vnd.apple.mpegurl')) {
                video.src = 'https://vms.dongthap.gov.vn:80/streams/63a570ae92b4550012bde46d/stream/63a570ae92b4550012bde46d.m3u8';
                video.addEventListener('canplay', function () {
                video.play();
                });
            }
        },
    }
</script>
<style>

</style>

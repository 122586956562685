/* eslint-disable */
import {readDataService} from "@/services/readData.services";
const actions = {
    async readData_NoTable({
        dispatch
    }, {actionCode}) {
        return await readDataService.readData_NoTable(actionCode);
    },
    async readData_WithMenuAppID({
        dispatch
    }, {actionCode,menuAppID}) {
        return await readDataService.readData_WithMenuAppID(actionCode,menuAppID);
    },
    async readData_WithTableID({
        dispatch
    }, {actionCode,tableId}) {
        return await readDataService.readData_WithTableID(actionCode,tableId);
    },

    async readData_WithTableId_DetailId({
        dispatch
    }, {actionCode,tableId,detailId}) {
        return await readDataService.readData_WithTableId_DetailId(actionCode,tableId,detailId);
    }

}
export const readData = {
    namespaced: true,
    actions

};

<template>
        <div class="wrapper">
            <TheNavBar></TheNavBar>


            <div class="content-wrapper">
                <div class="content-header">
                    <div class="container">
                    </div>
                </div>
                <div class="content">
                    <div class="card container">
                        <div class="card-header" style="margin-bottom: 50px;">
                            <h3 class="card-title"> ĐĂNG KÝ </h3>
                        </div>
                        <!-- /.card-header -->
                        <!-- <div class="card-body table-responsive p-0">
                        </div> -->
                        <div class="card-body p-0">
                            <!-- <form action="../../index3.html" method="post"> -->
                                <div class="row">
                                    <section class="col-lg-5 connectedSortable">
                                        <div class="card">
                                            <div class="card-header" style="background-color: #007bff;">
                                                <h3 class="card-title">
                                                    <b style="color:white">Thông tin đăng nhập</b>
                                                </h3>
                                                <div class="card-tools">
                                                </div>
                                            </div><!-- /.card-header -->
                                            <div class="card-body">
                                                <div class="tab-content p-0">
                                                    <div class="row">
                                                        <div class="form-group mb-3" style="width:100%">
                                                            <label for="loginName">Tên đăng nhập <span class="text-danger">(*)</span></label>
                                                            <input type="text" class="form-control" id="loginName" placeholder="Tên đăng nhập" v-model="model.userName" @blur="validate()">
                                                        </div>
                                                    </div>
                                                    <div class="text-danger text-right mb-2" style="margin-top: -13 !important;" v-if="errors.userName">
                                                        {{ errors.loginName }}</div>
                                                    <div class="row">
                                                        <div class="form-group mb-3" style="width:100%">
                                                            <label for="password">Mật khẩu <span class="text-danger">(*)</span></label>
                                                            <input type="password" class="form-control" id="password" placeholder="Mật khẩu" v-model="model.password" @blur="validate()">
                                                        </div>
                                                    </div>
                                                    <div class="text-danger text-right mb-2" style="margin-top: -13 !important;" v-if="errors.password">
                                                        {{ errors.password }}</div>
                                                    <div class="row">
                                                        <div class="form-group mb-3" style="width:100%">
                                                            <label for="passwordConfirm">Xác nhận mật khẩu <span class="text-danger">(*)</span></label>
                                                            <input type="password" class="form-control" id="passwordConfirm" placeholder="Xác nhận mật khẩu" v-model="model.passwordConfirm" @blur="validate()">
                                                        </div>
                                                    </div>
                                                    <div class="text-danger text-right mb-2" style="margin-top: -13 !important;" v-if="errors.passwordConfirm">
                                                        {{ errors.passwordConfirm }}</div>
                                                </div>
                                            </div><!-- /.card-body -->
                                        </div>
                                    </section>
                                    <section class="col-lg-7 connectedSortable">
                                        <div class="card">
                                            <div class="card-header" style="background-color: #007bff;">
                                                <h3 class="card-title">
                                                    <b style="color:white">Thông tin tài khoản</b>
                                                </h3>
                                            </div>
                                            <div class="card-body">
                                                <div class="tab-content p-0">
                                                    <div class="row">
                                                        <div class="form-group mb-3" style="width:100%">
                                                            <label for="userName">Họ tên <span class="text-danger">(*)</span></label>
                                                            <input type="text" class="form-control" id="userName" placeholder="Họ tên" v-model="model.hoTen" @blur="validate()">
                                                        </div>
                                                    </div>
                                                    <div class="text-danger text-right mb-2" style="margin-top: -13 !important;" v-if="errors.hoTen">
                                                        {{ errors.hoTen }}</div>
                                                    <div class="row">
                                                        <div class="form-group mb-3 col" style="width:100%">
                                                            <label for="phoneNumber">Số điện thoại <span class="text-danger">(*)</span></label>
                                                            <input type="text" class="form-control" id="phoneNumber" placeholder="Số điện thoại" v-model="model.sdt" @blur="validate()">

                                                        </div>
                                                        <div class="form-group mb-3 col" style="width:100%">
                                                            <label for="phoneNumberConfirm">Xác nhận số điện thoại <span class="text-danger">(*)</span></label>
                                                            <input type="text" class="form-control" id="phoneNumberConfirm" placeholder="Xác nhận số điện thoại" v-model="model.sdtConfirmed" @blur="validate()">
                                                        </div>
                                                    </div>
                                                    <div class="text-danger text-right mb-2" style="margin-top: -13 !important;" v-if="errors.sdt">
                                                        {{ errors.sdt }}</div>
                                                    <div class="text-danger text-right mb-2" style="margin-top: -13 !important;" v-if="errors.sdtConfirmed">
                                                        {{ errors.sdtConfirmed }}</div>
                                                    <div class="row">
                                                        <div class="form-group mb-3" style="width:100%">
                                                            <label for="Email">Email</label>
                                                            <input type="text" class="form-control" id="Email" placeholder="Email" v-model="model.email">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div><!-- /.card-body -->
                                        </div>
                                    </section>
                                </div>
                                <div class="row">
                                    <section class="connectedSortable col-lg-12">
                                        <div class="card">
                                            <div class="card-header" style="background-color: #007bff;">
                                                <h3 class="card-title">
                                                    <!-- <i class="fas fa-chart-pie mr-1"></i>
                                                    Sales -->
                                                    <b style="color:white">Địa chỉ thường trú trên địa bản Thành phố Cao Lãnh</b>
                                                </h3>
                                                <div class="card-tools">
                                                    <!-- <ul class="nav nav-pills ml-auto">
                                                    <li class="nav-item">
                                                    <a class="nav-link active" href="#revenue-chart" data-toggle="tab">Area</a>
                                                    </li>
                                                    <li class="nav-item">
                                                    <a class="nav-link" href="#sales-chart" data-toggle="tab">Donut</a>
                                                    </li>
                                                </ul> -->
                                                </div>
                                            </div><!-- /.card-header -->
                                            <div class="card-body">
                                                <div class="tab-content p-0">
                                                    <div class="row">
                                                        <!-- <div class="form-group col">
                                                            <label>Tỉnh/Thành phố</label>
                                                            <select class="form-control select2bs4" id="provinceOptions" style="width: 100%;" :disabled="disabledProvince" >
                                                                
                                                                <option v-for="item in provinceList" :key="item.tinhTpId" :value="item.tinhTpId" >{{
                                                                    item.tenTinhTp }}</option>
                                                            </select>
                                                        </div>

                                                        <div class="form-group col">
                                                            <label>Quận/huyện</label>
                                                            <select class="form-control select2bs4" id="districtOptions" style="width: 100%;" :disabled="disabledDistrict">                                                               
                                                                <option v-for="item in districtList" :key="item.quanHuyenId" :value="item.quanHuyenId">{{ item.tenQuanHuyen }}</option>
                                                            </select>
                                                        </div> -->

                                                        <div class="form-group col">
                                                            <label>Phường/xã <span class="text-danger">(*)</span></label>
                                                            <select class="form-control select2bs4" id="wardsOptions" style="width: 100%;" :disabled="disabledWards"  v-model="model.phuongXaId" @blur="validate()">                                                              
                                                                <option v-for="item in wardsList" :key="item.phuongXaId" :value="item.phuongXaId">{{ item.tenPhuongXa }}</option>
                                                            </select>
                                                        </div>
                                                        
                                                        <div class="form-group col">
                                                            <label>Khóm/ấp</label>
                                                            <select class="form-control select2bs4" id="hamletOptions" style="width: 100%;" :disabled="disabledHamlet">                                                                
                                                                <option v-for="item in hamletList" :key="item.khomApId" :value="item.khomApId">{{ item.tenKhomAp }}</option>
                                                            </select>
                                                        </div>

                                                        <div class="form-group col">
                                                            <label>Tổ dân phố</label>
                                                            <select class="form-control select2bs4" id="subquarterOptions" style="width: 100%;" :disabled="disabledSubQuarter">                                                                
                                                                <option v-for="item in subquarterList" :key="item.toDanPhoId" :value="item.toDanPhoId">{{ item.tenToDanPho }}</option>
                                                            </select>
                                                        </div>

                                                        <div class="form-group col">
                                                                <label for="streetOptions">Tên đường</label>
                                                                <input type="text" class="form-control" id="streetOptions">
                                                        </div>

                                                        <div class="form-group col">
                                                                <label for="numberAddressOptions">Số nhà</label>
                                                                <input type="number" class="form-control" id="numberAddressOptions">
                                                        </div>
                                                    </div>

                                                    <div class="row">
                                                        <div class="text-danger text-right mb-2" style="margin-top: -13 !important;" v-if="errors.wards">
                                                        {{ errors.wards }}</div>
                                                    </div>

                                                    <div class="row">
                                                        <div class="form-group mb-3" style="width:100%">
                                                            <label for="fullAddress">Địa chỉ</label>
                                                            <input type="text" class="form-control" id="fullAddress" placeholder="Địa chỉ" readonly="readonly" style="background-color: aliceblue;" >
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>

                                    </section>
                                </div>

                                <div class="row">
                                    <div class="col text-center">
                                        <button type="submit" class="btn btn-primary" @click="onSubmit();">Đăng ký</button>
                                    </div>
                                </div>
                            <!-- </form> -->
                        </div>
                    </div>
                </div>
            </div>
            <aside class="control-sidebar control-sidebar-dark">
            </aside>

            <footer class="main-footer">
            </footer>
        </div>
</template>

<script>
import $ from 'jquery'
import { mapActions } from 'vuex'
import TheNavBar from "@/components/dashboard/TheNavBar.vue"
//Model
import { provinceModel } from "@/models/country_unit/province.model"
import { districtModel } from '@/models/country_unit/district.model'
import { wardsModel } from '@/models/country_unit/wards.model'
import { hamletModel } from '@/models/country_unit/hamlet.model'
import { subquarterModel } from '@/models/country_unit/sub-quarter.model'
import { userModel } from '@/models/user/user.model'
export default {
    name: "TheLoginPage",
    components: {
        TheNavBar
    },
    data() {
        return {
            provinceList: Array,
            districtList: Array,
            wardsList: Array,
            hamletList: Array,
            subquarterList: Array,

            disabledProvince: false,
            disabledDistrict: true,
            disabledWards: true,
            disabledHamlet: true,
            disabledSubQuarter: true,

            wardsSelectedText: '',
            hamletSelectedText: '',
            subquarterSelectedText: '',
            streetText: '',
            numberAddressText: '',

            model: userModel.newModel(),

            errors:{
                loginName: '',
                password: '',
                passwordConfirm: '',
                userName: '',
                phoneNumber: '',
                phoneNumberConfirm: '',
                wards: '',

            }
        }

    },
    methods: {
        ...mapActions('countryUnit', ['readData_Province', 'readData_District', 'readData_Wards', 'readData_Hamlet', 'readData_SubQuarter']),
        ...mapActions('user',['register']),
        //#region GetData
        async getProvince() {
            await this.readData_Province().then(res => {
                this.provinceList = provinceModel.toListModel(res);
            });
        },

        async getDistrict(tinhTpId) {
            await this.readData_District({"tinhTpId": tinhTpId}).then(res => {
                this.districtList = districtModel.toListModel(res);
            });
        },

        async getWards(quanHuyenId) {
            await this.readData_Wards({"quanHuyenId": quanHuyenId}).then(res => {
                this.wardsList = wardsModel.toListModel(res);
            });
        },

        async getHamlet(phuongXaId) {
            await this.readData_Hamlet({"phuongXaId": phuongXaId}).then(res => {
                this.hamletList = hamletModel.toListModel(res);
            });
        },

        async getSubQuarter(khomApId) {
            await this.readData_SubQuarter({"khomApId": khomApId}).then(res => {
                this.subquarterList = subquarterModel.toListModel(res);
            });
        },
        //#endregion GetData


        //#region FormControl
        async controlCountryUnit(controlID,id) {
            //Province change
            if(controlID == 1){
                this.disabledDistrict = false;
                this.disabledWards = true;
                this.disabledHamlet = true;
                this.disabledSubQuarter = true;
                this.districtList = [];
                this.wardsList = [];
                this.hamletList = [];
                this.subquarterList = [];
                await this.getDistrict(id);
            }
            else if(controlID == 2){
                this.disabledWards = false;
                this.disabledHamlet = true;
                this.disabledSubQuarter = true;
                this.wardsList = [];
                this.hamletList = [];
                this.subquarterList = [];
                await this.getWards(id);
            }
            else if(controlID == 3){
                this.disabledHamlet = false;
                this.disabledSubQuarter = true;
                this.hamletList = [];
                this.subquarterList = [];
                this.wardsSelectedText = '';
                this.hamletSelectedText ='';
                this.subquarterSelectedText =  '';
                await this.getHamlet(id);
            }

            else if(controlID == 4){
                this.disabledSubQuarter = false;
                this.subquarterList = [];
                this.hamletSelectedText ='';
                this.subquarterSelectedText =  '';
                await this.getSubQuarter(id);
            }
            else if(controlID == 5){
                this.subquarterSelectedText =  '';
            }

        },

        async controlFullAddress(){
            let tempText = "";

            if(this.numberAddressText != '' && this.numberAddressText != null){
                tempText += this.numberAddressText + ", ";
            }
            
            if(this.streetText != '' && this.streetText != null){
                tempText += this.streetText + ", ";
            }

            if(this.subquarterSelectedText != '' && this.subquarterSelectedText != null){
                tempText += this.subquarterSelectedText + ", ";
            }

            if(this.hamletSelectedText != '' && this.hamletSelectedText != null){
                tempText += this.hamletSelectedText + ", ";
            }

            if(this.wardsSelectedText != '' && this.wardsSelectedText != null){
                tempText += this.wardsSelectedText + ", ";
            }

            tempText += "Thành phố Cao Lãnh, ";
            tempText += "Đồng Tháp";
            $('#fullAddress').val(tempText).trigger("change");
            this.model.diaChi = tempText;
        },
        //#endregion FormControl

        //#region Validation
        async validate() {
            let isvalid = true
            // set up errors
            this.errors ={
                userName: '',
                password: '',
                passwordConfirm: '',
                hoTen: '',
                phoneNumber: '',
                phoneNumberConfirm: '',
            }
            // check null model
            if(!this.model.userName){
                this.errors.userName = "Nhập tên đăng nhập";
                isvalid = false;
            }
            if(!this.model.password){
                this.errors.password = "Nhập mật khẩu";
                isvalid = false;
            }
            if(!this.model.passwordConfirm){
                this.errors.passwordConfirm = "Nhập xác nhận mật khẩu";
                isvalid = false;
            }

            if(!this.model.hoTen){
                this.errors.hoTen = "Nhập Họ tên";
                isvalid = false;
            }

            if(!this.model.sdt){
                this.errors.sdt = "Nhập số điện thoại";
                isvalid = false;
            }

            if(!this.model.sdtConfirmed){
                this.errors.sdtConfirmed = "Nhập xác nhận số điện thoại";
                isvalid = false;
            }

            if(!this.model.phuongXaId){
                this.errors.wards = "Chưa chọn Phường Xã";
                isvalid = false;
            }
            return isvalid
        },
        //#endregion Validation

        //#region Submit
        async onSubmit(){
            console.log(this.model);
            this.submitted = true;
            if (await this.validate()) {
                console.log("OK");
                await this.register({"user": this.model}).then(res => {
                //this.districtList = districtModel.toListModel(res);
            });
            }
            this.submitted = false;
        }
        //#endregion Submit

    },
    async mounted() {

        //Initialize Select2 Elements
        $(".select2").select2();
        //Initialize Select2 Elements
        $(".select2bs4").select2({
            theme: "bootstrap4",
        });



        // await this.getProvince();
        // $('#provinceOptions').val(null).trigger("change");

        // $('#provinceOptions').on('change', async e => {
        //     let id = e.target.value;
        //     if(id != "")
        //     {
        //         //87
        //         await this.controlCountryUnit(1,id);
        //         $('#districtOptions').val(null).trigger("change");
        //     }
        // });

        // $('#districtOptions').on('change', async e => {
        //     let id = e.target.value;
        //     if(id != ""){
        //         //866
        //         console.log(id);
        //         await this.controlCountryUnit(2,id);
        //         $('#wardsOptions').val(null).trigger("change");
        //     }
        // });

        await this.controlCountryUnit(2,'866');
        $('#wardsOptions').val(null).trigger("change");

        $('#wardsOptions').on('change', async e => {
            let id = e.target.value;
            this.model.phuongXaId = id;
            let optionsNumber = e.target.selectedIndex;
            if(id != ""){
                await this.controlCountryUnit(3,id);
                let a = e.target.options[optionsNumber];
                this.wardsSelectedText =e.target.options[optionsNumber].label;
                $('#hamletOptions').val(null).trigger("change");
                this.controlFullAddress();
            }
        });

        $('#hamletOptions').on('change', async e => {
            let id = e.target.value;
            this.model.khomApId = id;
            let optionsNumber = e.target.selectedIndex;
            if(id != ""){
                await this.controlCountryUnit(4,id);
                this.hamletSelectedText =e.target.options[optionsNumber].label;
                $('#subquarterOptions').val(null).trigger("change");
                this.controlFullAddress();
            }
        });

        $('#subquarterOptions').on('change', async e => {
            let id = e.target.value;
            this.model.toDanPhoId = id;
            let optionsNumber = e.target.selectedIndex;
            if(id != ""){
                await this.controlCountryUnit(5,id);
                this.subquarterSelectedText =e.target.options[optionsNumber].label;
                this.controlFullAddress();
            }
        });

        $('#streetOptions').on('change', async e => {
            let text = e.target.value;
            if(text != ""){
                this.streetText = text;
                this.controlFullAddress();
            }
        });

        $('#numberAddressOptions').on('change', async e => {
            let text = e.target.value;
            if(text != ""){
                this.numberAddressText = text;
                this.controlFullAddress();
            }
        });

        await this.controlFullAddress();



    }
}
</script>

<style>
    .vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    }
    .center {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    }
</style>


<!-- 

    
 -->
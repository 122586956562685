// eslint-disable-next-line
import router from '@/router/index.js';
import {authenticationService} from '@/services/authentication.services';
// eslint-disable-next-line
const noLoginTokens = JSON.parse(localStorage.getItem('noLoginTokens'));

const actions = {
    async getNoTokens({dispatch}) {
        // let resp =  await authenticationService.getTokens_NoLogin();
        // if(resp.statusCode != 200){
        //     dispatch('alert/error', 'Lấy Tokens Thất bại',{ root: true });
        //     return false;
        // } else {
        //     dispatch('alert/success', 'Lấy Tokens Thành công', { root: true });
        //     return resp;
        // }
        // dispatch('alert/success', 'Lấy Tokens Thành công', { root: true });
        // return res;
        await authenticationService.getTokens_NoLogin().then(
            // eslint-disable-next-line
                resp => { // commit('loginSuccess', data);
                dispatch('alert/success', 'Lấy Tokens Thành công', {root: true});
                return resp;
            },
            // eslint-disable-next-line
                (error) => {
                dispatch('alert/error', 'Lấy Tokens Thất bại', {root: true});
                return false;
                // console.log(error);
            }
        )
    },
};


export const authentication = {
    namespaced: true,
    actions

};

const newModel = () => {
    return {
        toDanPhoId: null,
        tenToDanPho: "",
        stt: null,
        khomApId: null,
        tenKhomAp: "",

        }
}

const fromJson = (item) => {
    return {
        toDanPhoId: item.toDanPhoId,
        tenToDanPho: item.tenToDanPho,
        khomApId: item.khomApId,
        tenKhomAp: item.tenKhomAp,
        stt: item.stt,
    }
}

const toListModel = (items) =>{
    if(items.length > 0){
        let data = [];
        items.map((value, index) =>{
            data.push(fromJson(value));
        })
        return data??[];
    }
    return [];
}

export const subquarterModel = {
    newModel,
    toListModel
}


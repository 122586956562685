import Vue from 'vue';
import { createWebHistory, createRouter } from "vue-router";
import TheMainScreen from "@/components/pages/TheMainScreen.vue"
import TheListPage from "@/components/pages/TheListPage.vue"
import TheVideoPage from "@/components/pages/TheVideoPage.vue"
import TheLoginPage from "@/components/pages/AccountManagement/TheLoginPage.vue"
import TheRegisterPage from "@/components/pages/AccountManagement/TheRegisterPage.vue"
const routes = [

  {
    path: "/",
    name: "TheMainScreen",
    component: TheMainScreen,
    props: true,

    // meta: {
    //   authRequired: true,
    // },
  },
  {
    path: "/list-page/:menuNameCha/:menuNameCon/:id",
    //path: "/list-page/:menuNameCha/:id",
    name: "TheListPage",
    component: TheListPage,
    props: true,

    // meta: {
    //   authRequired: true,
    // },
  },


  {
    path: "/TheVideoPage",
    name: "TheVideoPage",
    component: TheVideoPage,
    props: true,

    // meta: {
    //   authRequired: true,
    // },
  },
  {
    path: "/TheLoginPage",
    name: "TheLoginPage",
    component: TheLoginPage,
    props: true,
  },
  {
    path: "/TheRegisterPage",
    name: "TheRegisterPage",
    component: TheRegisterPage,
    props: true,
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// router.beforeEach((to, from, next) => {
//   let loggedIn = store.state.authentication.status.loggedIn;
//   let user = JSON.parse(localStorage.getItem("user_local"));
//   loggedIn = user && Date.parse(user.expiryDate) > Date.now() ? true : false;
//   let authPage = to.meta.authRequired;
//   if (authPage && !loggedIn) {
//     next("/login");
//   } else {
//     next();
//   }

//   // if (!to.meta.authRequired) {
//   //   next();
//   // } else if (to.meta.authRequired && !loggedIn) {
//   //   return next("/login");
//   // } else {
//   //   const user = JSON.parse(localStorage.getItem("user_local"));
//   //   if (Date.parse(user.expiryDate) > Date.now()) {
//   //     next();
//   //   } else {
//   //     next("/login");
//   //   }
//   // }
// });

const DEFAULT_TITLE = 'Cao Lãnh SmartCity';
router.beforeEach((to, from, next) => {
  document.title = DEFAULT_TITLE;
  next();
});

export default router;

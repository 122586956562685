/* eslint-disable */
import config from '../config/prod.env';
import {header} from '../helper/header';
import {common} from '../helper/common';

async function readData_NoTable(actionCode){
    const requestOptions = {
        method: 'POST',
        headers: await header.authHeaderWithToken_NoLogin(),
        body: JSON.stringify({actionCode})
    }
    return await fetch(`${config.API_URL}/ReadAppData/QueryData`, requestOptions).then(response => common.handleResponse(response)).then(res => {
        return(res.data);
    });
}
async function readData_WithMenuAppID(actionCode,menuAppID){
    const requestOptions = {
        method: 'POST',
        headers: await header.authHeaderWithToken_NoLogin(),
        body: JSON.stringify({actionCode,menuAppID})
    }
    return await fetch(`${config.API_URL}/ReadAppData/QueryData`, requestOptions).then(response => common.handleResponse(response)).then(res => {
        return(res.data);
    });
}

async function readData_WithTableID(actionCode,tableId){
    const requestOptions = {
        method: 'POST',
        headers: await header.authHeaderWithToken_NoLogin(),
        body: JSON.stringify({actionCode,tableId})
    }
    return await fetch(`${config.API_URL}/ReadAppData/QueryData`, requestOptions).then(response => common.handleResponse(response)).then(res => {
        return(res.data);
    });
}

async function readData_WithTableId_DetailId(actionCode,tableId,detailId){
    const requestOptions = {
        method: 'POST',
        headers: await header.authHeaderWithToken_NoLogin(),
        body: JSON.stringify({actionCode,tableId,detailId})
    }
    return await fetch(`${config.API_URL}/ReadAppData/QueryData`, requestOptions).then(response => common.handleResponse(response)).then(res => {
        return(res.data);
    });
}

export const readDataService = {
    readData_NoTable,
    readData_WithMenuAppID,
    readData_WithTableID,
    readData_WithTableId_DetailId
};

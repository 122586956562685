const newModel = () => {
    return {
        userName: '',
        password: '',
        passwordConfirm: '',
        hoTen: '',
        email: '',
        sdt: null,
        sdtConfirmed: null,
        diaChi: '',
        quanHuyenId: null,
        phuongXaId: null,
        khomApId: null,
        toDanPhoId: null

        //Add more
    }
}

const fromJson = (item) => {
    return {
        userName: item.userName,
        password: item.password,
        passwordConfirm: item.passwordConfirm,
        hoTen: item.hoTen,
        email: item.email,
        sdt: item.sdt,
        sdtConfirmed: item.sdtConfirmed,
        diaChi: item.diaChi,
        quanHuyenId: item.quanHuyenId,
        phuongXaId: item.phuongXaId,
        khomApId: item.khomApId,
        toDanPhoId: item.toDanPhoId,

        //Add more

    }
}

const toListModel = (items) =>{
    if(items.length > 0){
        let data = [];
        items.map((value, index) =>{
            data.push(fromJson(value));
        })
        return data??[];
    }
    return [];
}

export const userModel = {
    newModel,
    toListModel
}


/* eslint-disable */
import config from '../config/prod.env';
import {header} from '../helper/header';
import {common} from '../helper/common';



async function readData_Province(){
    const requestOptions = {
        method: 'POST',
        headers: await header.authHeaderWithToken_NoLogin(),
        body: JSON.stringify({actionCode:22})
    }
    return await fetch(`${config.API_URL}/DsTinhTp/QueryData`, requestOptions).then(response => common.handleResponse(response)).then(res => {
        return(res.data);
    });
}

async function readData_District(tinhTpId){
    const requestOptions = {
        method: 'POST',
        headers: await header.authHeaderWithToken_NoLogin(),
        body: JSON.stringify({actionCode:22,tinhTpId})
    }
    return await fetch(`${config.API_URL}/DsQuanHuyen/QueryData`, requestOptions).then(response => common.handleResponse(response)).then(res => {
        return(res.data);
    });
}

async function readData_Wards(quanHuyenId){
    const requestOptions = {
        method: 'POST',
        headers: await header.authHeaderWithToken_NoLogin(),
        body: JSON.stringify({actionCode:22,quanHuyenId})
    }
    return await fetch(`${config.API_URL}/DsPhuongXa/QueryData`, requestOptions).then(response => common.handleResponse(response)).then(res => {
        return(res.data);
    });
}

async function readData_Hamlet(phuongXaId){
    const requestOptions = {
        method: 'POST',
        headers: await header.authHeaderWithToken_NoLogin(),
        body: JSON.stringify({actionCode:22,phuongXaId})
    }
    return await fetch(`${config.API_URL}/DsKhomAp/QueryData`, requestOptions).then(response => common.handleResponse(response)).then(res => {
        return(res.data);
    });
}

async function readData_SubQuarter(khomApId){
    const requestOptions = {
        method: 'POST',
        headers: await header.authHeaderWithToken_NoLogin(),
        body: JSON.stringify({actionCode:22,khomApId})
    }
    return await fetch(`${config.API_URL}/DsToDanPho/QueryData`, requestOptions).then(response => common.handleResponse(response)).then(res => {
        return(res.data);
    });
}


export const countryUnitService = {
    readData_Province,
    readData_District,
    readData_Wards,
    readData_Hamlet,
    readData_SubQuarter,
};

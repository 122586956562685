import config from '../config/prod.env';
import {header} from '../helper/header';

import {common} from '../helper/common';
import constant from '../constant/constant'

async function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: header.authHeaderNoToken(),
        body: JSON.stringify(
            {
                "userName": user.userName,
                "password": user.password,
                "hoTen": user.hoTen,
                "email": user.email,
                "sdt": user.sdt,
                "sdtConfirmed": user.sdtConfirmed,
                "diaChi": user.diaChi,
                "quanHuyenId":"87" ,
                "phuongXaId": "866",
                "khomApId": user.khomApId + "",
                "toDanPhoId": user.toDanPhoId + "",

            }
        ),
    }

    return fetch(`${config.API_URL}/NguoiDung/SignUp`, requestOptions).then(response => common.handleResponse(response));
}

async function login(user) {
    const requestOptions = {
        method: 'POST',
        headers: header.authHeaderNoToken(),
        body: JSON.stringify(
            {
                "userName": user.userName,
                "password": user.password,
            }
        ),
    }

    return fetch(`${config.API_URL}/NguoiDung/SignIn`, requestOptions).then(response => common.handleResponse(response)).then(res => {
        // login thành công nếu có một token jwt trong response
        if (res.data.accessToken) {
            localStorage.setItem('userTokens', JSON.stringify(res.data));
            localStorage.setItem('rememberMe', user.rememberMe);
            if(user.rememberMe == true){
                localStorage.setItem('userName', user.userName);
                localStorage.setItem('password',user.password);
            }
        }
        return(res);
    });
}

async function reLogin(user) {
    const requestOptions = {
        method: 'POST',
        headers: header.authHeaderNoToken(),
        body: JSON.stringify(
            {
                "userName": user.userName,
                "password": user.password,
            }
        ),
    }

    return fetch(`${config.API_URL}/NguoiDung/SignIn`, requestOptions).then(response => common.handleResponse(response)).then(res => {
        // login thành công nếu có một token jwt trong response
        if (res.statusCode == 200) {
            localStorage.setItem('userTokens', JSON.stringify(res.data));
            localStorage.setItem('rememberMe', user.rememberMe);
            return true;
        }
        return false;
    });
}

async function refreshTokens_user(accessToken,refreshToken) {
    const requestOptions = {
        method: 'POST',
        headers: header.authHeaderNoToken(),
        body: JSON.stringify(
            {accessToken,refreshToken}
        )
    }
    return await fetch(`${config.API_URL}/NguoiDung/RegenerateNewToken`, requestOptions).then(response => common.handleResponse(response)).then(res => {
        // login thành công nếu có một token jwt trong response
        if (res.data.accessToken) {
            localStorage.setItem('userTokens', JSON.stringify(res.data));
            return true;
        }
        return false;
    });
}



export const userService = {
    register,
    login,
    reLogin,
    refreshTokens_user,
};


/* eslint-disable */
import  router from '@/router/index';
import {authenticationService} from '@/services/authentication.services';
export const header = {
    authHeaderNoToken,
    authHeaderWithToken,
    authHeaderWithToken_NoLogin
};

function authHeaderNoToken(){
    return { 'Content-Type': 'application/json'};
}
// eslint-disable-next-line
function authHeaderWithToken(){
    let user = JSON.parse(localStorage.getItem('user_local'));
    if (user && user.accessToken && Date.parse(user.expiryDate) > Date.now()) {
        return { 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user.accessToken};
    } else {
        //dispatch('alert/warning', 'Phiên đăng nhập của bạn đã hết, vui lòng đăng nhập lại',{ root: true });
        //router.push({ path: '/login' });
    }
}

async function authHeaderWithToken_NoLogin(){
    let noUser = JSON.parse(localStorage.getItem('noLoginTokens'));
    if (noUser && noUser.accessToken && (noUser.expiresTime * 1000) > Date.now()) {
        return { 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + noUser.accessToken};
    } 
    else if( noUser && noUser.accessToken && (noUser.expiresTime * 1000 + 3600) > Date.now())
    {
        let dataTokens = await authenticationService.refreshTokens_NoLogin();
        return { 'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + dataTokens.accessToken};
    }
    else {
        let dataTokens = await authenticationService.getTokens_NoLogin();
        return { 'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + dataTokens.accessToken};
    }
}


const newModel = () => {
    return {
        groupName: null,
        tableId: null,
        detailId: null,
        ten: null,
        sdt: null,
        diaChi: null,
        gpsLong: null,
        gpsLat: null,
        stt: null,
        GPS: null,
    }
}

const fromJson = (item) => {
    return {
        groupName: item.groupName,
        tableId: item.tableId,
        detailId: item.detailId,
        ten:  item.ten,
        sdt:  item.sdt,
        diaChi:  item.diaChi,
        gpsLong:  item.gpsLong,
        gpsLat:  item.gpsLat,
        stt:  item.stt,
        GPS:  item.GPS,
    }
}

const toListModel = (items) =>{
    if(items.length > 0){
        let data = [];
        items.map((value, index) =>{
            data.push(fromJson(value));
        })
        return data??[];
    }
    return [];
}

export const listItemModel = {
    newModel,
    toListModel
}



<template>
  <!-- <l-map ref="mapRef" style="height: 700px" :zoom="zoom" :center="center" :draggable="false" @update:zoom="zoomUpdated" @update:center="centerUpdated" @update:bounds="boundsUpdated" @ready="doSomethingOnReady()">
    <l-tile-layer :url="url"></l-tile-layer> -->
    <!-- <l-geo-json v-for="sector in data" :key="sector.id" :geojson="sector" :options="options" ref="geojson"> -->
    <!-- <l-geo-json v-for="sector in data" :key="sector.id" :geojson="sector" :options="options" ref="geojson"> -->
    <!-- <l-marker v-for="marker in markers" :key="marker.detailId" ref="markersRef" :lat-lng="marker.position"> 
      <l-tooltip :content="marker.ten" />
      <l-popup>
        <div @click="innerClick" style="cursor: pointer;">
          <span>{{ marker.ten }}</span>
          <p v-show="showPopup">
            Địa chỉ:{{ marker.diaChi }}
          </p>
          <p v-show="showPopup">
            Tọa độ:{{ marker.position.lat }} - {{ marker.position.lng }}
          </p>
        </div>
      </l-popup>
    </l-marker> -->
  <!-- </l-map> -->

  <l-map style="height: 700px" :zoom="zoom" :center="center" :draggable="false">
    <l-tile-layer :url=url></l-tile-layer>
    <l-marker v-for="marker in markers" :key="marker.detailId" ref="markersRef" :lat-lng="marker.position"> 
      <l-tooltip :content="marker.ten" />
      <l-popup>
        <div @click="innerClick" style="cursor: pointer;">
          <span>{{ marker.ten }}</span>
          <p v-show="showPopup">
            Địa chỉ: {{ marker.diaChi }}
          </p>
          <p v-show="showPopup">
            Tọa độ:{{ marker.position.lat }} - {{ marker.position.lng }}
          </p>
        </div>
      </l-popup>
    </l-marker>
  </l-map>
</template>

<script>
/* eslint-disable */
// Import lib
//import L, { map, marker } from "leaflet";
//import { LMap, LTileLayer, LMarker, LPopup, LTooltip, LGeoJson } from "@vue-leaflet/vue-leaflet"
import { LMap, LTileLayer, LMarker, LPopup, LTooltip, } from "@vue-leaflet/vue-leaflet";
// Icon
L.Icon.Default.imagePath = "https://unpkg.com/leaflet@1.3.4/dist/images/";
// CSS
import "leaflet/dist/leaflet.css";
import "leaflet/dist/leaflet.js";
import districts from "../../config/districtsGeoJson.json"
import $ from 'jquery'
export default {
  name: "TheMap",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
    LPopup,
    //map,
    //marker,
    //L
    //LGeoJson,
    //map: null,
  },

  props: {
    markersList: Array,
  },

  data() {
    return {
      url: 'https://maps.googleapis.com/maps/vt?pb=!1m5!1m4!1i{z}!2i{x}!3i{y}!4i256!2m3!1e0!2sm!3i349018013!3m9!2sen-US!3sUS!5e18!12m1!1e47!12m3!1e37!2m1!1ssmartmaps!4e0',
      zoom: 20,
    
      center: {'lat':10.458592353420453,'lng': 105.6385850684874},
      districts: null,
      data: null,
      color: "#ff00ff",
      enableTooltip: true,
      showPopup: true,
      markers: Array
    };

  },
  computed: {
    // options() {
    //   return {
    //     onEachFeature: this.onEachFeatureFunction
    //   };
    // },
    // event mouse in map
    onEachFeatureFunction() {
      // style
      var mystyle = {
        default: {
          weight: 2,
          color: "#ff00ff",
          opacity: 1,
          fillOpacity: 0.1
        },
        click: {
          weight: 2,
          color: "#ff00ff",
          opacity: 1,
          fillOpacity: 0.8
        }
      }
      // select sector
      var selected = null
      if (!this.enableTooltip) {
        return () => { };
      }
      return (feature, layer) => {
        //
        layer.setStyle({
          weight: 2,
          color: "#ff00ff",
          opacity: 1,
          fillColor: feature.properties.color,
          fillOpacity: 0.1
        })
        // tooltop
        layer.bindTooltip(
          "<div>Huyện: " +
          feature.properties.name +
          "</div><div>Diện tích: " +
          feature.properties.dientich +
          " km2 </div>",
          { permanent: false, sticky: true }
        )
        // onclick
        layer.on('click', function (e) {
          layer = e.target;
          // See if there is already a selection
          let previous = null
          if (selected !== null && selected !== undefined) {
            // Store for now
            previous = selected;
          }
          // Set new selection
          layer.setStyle(mystyle.click)
          selected = layer;
          // If there was a previous selection
          if (previous) {
            if (previous === selected) {
              selected = null
            }
            layer = previous
            // Dehighlight previous
            layer.setStyle(mystyle.default)
          }
        });
      }
    }
  },
  methods: {
    async zoomUpdated(zoom) {
      this.zoom = zoom;
      console.log(this.zoom);
    },
    async centerUpdated (center) {
      this.center = center;
    },
    async boundsUpdated (bounds) {
      this.bounds = bounds;
    },
    async innerClick() {
      //this.showPopup = !this.showPopup;
      //alert('zing.vn')
      //this.center = [20.835620, 105.826849];
    },

    async focusMarker(lat, lng) {
      //this.zoom = 20;
      this.center = {lat,lng};
      this.$refs.markersRef.forEach(item => {
        if(item.latLng.lat == lat && item.latLng.lng == lng)
          item.leafletObject.openPopup();
      });
    },




  },
  async created() {
    // hide title bottom map
   


    let response = districts.features
    this.data = response;
    this.markers = await this.markersList;
  },
  mounted() {
    // hide title bottom map
    $('.leaflet-control-attribution leaflet-control').hide();
  },

}
</script>
const newModel = () => {
    return {
        khomApId: null,
        tenKhomAp: "",
        phuongXaId: null,
        tenPhuongXa: "",
        stt: null,
        }
}

const fromJson = (item) => {
    return {
        khomApId: item.khomApId,
        tenKhomAp: item.tenKhomAp,
        phuongXaId: item.phuongXaId,
        tenPhuongXa: item.tenPhuongXa,
        stt: item.stt,
    }
}

const toListModel = (items) =>{
    if(items.length > 0){
        let data = [];
        items.map((value, index) =>{
            data.push(fromJson(value));
        })
        return data??[];
    }
    return [];
}

export const hamletModel = {
    newModel,
    toListModel
}


import "../../../node_modules/vue-toastification/dist/index.css"
import { createToastInterface } from "vue-toastification";

  
const pluginOptions = ({
    timeout: 3000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: true,
    hideProgressBar: false,
    closeButton: 'button',
    icon: true,
    rtl: false
   });
  
  // Create the interface
const toast = createToastInterface(pluginOptions);

const state = {
    type: '',
    message: ''
};

const actions = {
    success({ commit }, message) {
        commit('success', message);
    },
    error({ commit }, message) {
        commit('error', message);
    },
    warning({ commit }, message) {
        commit('warning', message);
    },
    info({ commit }, message) {
        commit('info', message);
    },
    clear({ commit }, message) {
        commit('success', message);
    }
};

const mutations = {
    success(state, message) {
        state.type = 'alert-success';
        state.message = message;
        toast.success(message);
    },
    error(state, message) {
        state.type = 'alert-danger';
        state.message = message;
        toast.error(message);
    },
    warning(state, message) {
        state.type = 'alert-warning';
        state.message = message;
        toast.warning(message);
    },
    info(state, message) {
        state.type = 'alert-info';
        state.message = message;
        toast.info(message);
    },
    clear(state) {
        state.type = null;
        state.message = null;
    }
};

export const alert = {
    namespaced: true,
    state,
    actions,
    mutations
};

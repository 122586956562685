<template>
    <div class="wrapper">
      <TheNavBar></TheNavBar>

      <!-- Content Wrapper. Contains page content -->
      <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
          <div class="container">
            <!-- <div class="row mb-2">
                            <div class="col-sm-6">
                                <h1 class="m-0"> Top Navigation <small>Example 3.0</small></h1>
                            </div>
                            <div class="col-sm-6">
                                <ol class="breadcrumb float-sm-right">
                                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                                    <li class="breadcrumb-item"><a href="#">Layout</a></li>
                                    <li class="breadcrumb-item active">Top Navigation</li>
                                </ol>
                            </div>
                        </div> -->
          </div>
        </div>
        <!-- /.content-header -->

        <!-- Main content -->
        <div class="content">
          <div class="card container">
            <div class="card-header">
              <h3 class="card-title">DANH SÁCH {{ String(nameList).toUpperCase() }}</h3>
            </div>
            <!-- /.card-header -->
            <div class="card-body table-responsive p-0" style="height: 350px; ">
              <table class="table table-head-fixed table-striped">
                <thead>
                  <tr>
                    <th style="width: 5%">STT</th>
                    <th style="width: 25%">Tên</th>
                    <th style="width: 12%">Số điện thoại</th>
                    <th >Địa chỉ</th>
                    <th style="width: 18%">Thao tác</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item,index) in listItem" :key="item.menuAppID">
                    <td>{{ index +1 }}</td>
                    <td>{{ item.ten }}</td>
                    <td>
                      {{ item.sdt }}
                    </td>
                    <td>
                      {{ item.diaChi }}
                    </td>
                    <td>
                      <a href="#" v-on:click="showDetail(item)">Xem chi tiết</a>
                      <br>
                      <div v-if="item.gpsLat != '' && item.gpsLat != null">
                        <a href="#"   v-on:click="focusMapEvent(parseFloat(item.gpsLat),parseFloat(item.gpsLong))">Đánh dấu trên bản đồ</a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- /.card-body -->
          </div>

          <div class="card container">
             <TheMap v-if="isCompleted" :markersList="listMapItem"  ref="map" /> 
          </div>
        </div>
        <!-- /.content -->
      </div>
      <!-- /.content-wrapper -->

      <!-- Control Sidebar -->
      <aside class="control-sidebar control-sidebar-dark">
        <!-- Control sidebar content goes here -->
      </aside>
      <!-- /.control-sidebar -->

      <!-- Main Footer -->
      <footer class="main-footer">
        <!-- To the right -->
        <!-- <div class="float-right d-none d-sm-inline">
                    Anything you want
                </div> -->
        <!-- Default to the left -->
        <!-- <strong>Copyright &copy; 2014-2021 <a href="https://adminlte.io">AdminLTE.io</a>.</strong> All rights
                reserved. -->
      </footer>
    </div>
    <!-- ./wrapper -->

  <!--POP UP DETAIL-->
  <div class="modal fade" id="detailModal" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="card card-primary">
          <div class="card-header">
            <h3 class="card-title">Thông tin chi tiết</h3>
          </div>

          <div class="card-body box-profile">
            <div v-if="detailValue.ten != '' && this.detailValue.ten != null">
              <h3 class="profile-username text-center">{{ detailValue.ten }}</h3>
              <div v-if="detailValue.hinhAnh != '' && this.detailValue.hinhAnh != null" class="text-center">
                  <img class="img-fluid"
                      :src="detailValue.hinhAnh"
                       alt="User profile picture">
                </div>
              <hr>
            </div> 
            <!-- <div v-if="detailValue.hinhAnh != '' && this.detailValue.hinhAnh != null">
              <strong>{{ detailName.hinhAnh }}</strong>
              <div class="widget-user-image">
                <img :src="detailValue.hinhAnh" alt="menu-avatar" class=" img-fluid"
                  style="  display: block;margin-left: auto;margin-right: auto;">
              </div>
              <hr>
            </div> -->
            
            <div v-if="detailValue.sdt != '' && this.detailValue.sdt != null">
              <strong>{{ detailName.sdt }}</strong>
              <p class="text-muted">
                {{ detailValue.sdt }}
              </p>
              <hr>
            </div>

            <div v-if="detailValue.tuyenDuong != '' && this.detailValue.tuyenDuong != null">
              <strong>{{ detailName.tuyenDuong }}</strong>
              <p class="text-muted">
                {{ detailValue.tuyenDuong }}
              </p>
              <hr>
            </div>

            <div v-if="detailValue.diaChi != '' && this.detailValue.diaChi != null">
              <strong>{{ detailName.diaChi }}</strong>
              <p class="text-muted">
                {{ detailValue.diaChi }}
              </p>
              <hr>
            </div>

            <div v-if="detailValue.gioiThieu != '' && this.detailValue.gioiThieu != null">
              <strong>{{ detailName.gioiThieu }}</strong>
              <p class="text-muted">
                {{ detailValue.gioiThieu }}
              </p>
              <hr>
            </div>

            <div v-if="detailValue.khungGio != '' && this.detailValue.khungGio != null">
              <strong>{{ detailName.khungGio }}</strong>
              <p class="text-muted">
                {{ detailValue.khungGio }}
              </p>
              <hr>
            </div>

            <div v-if="detailValue.loTrinh != '' && this.detailValue.loTrinh != null">
              <strong>{{ detailName.loTrinh }}</strong>
              <p class="text-muted">
                {{ detailValue.loTrinh }}
              </p>
              <hr>
            </div>

            <div v-if="detailValue.gioMoCua != '' && this.detailValue.gioMoCua != null">
              <strong>{{ detailName.gioMoCua }}</strong>
              <p class="text-muted">
                {{ detailValue.gioMoCua }}
              </p>
              <hr>
            </div>

            <div v-if="detailValue.gianCach != '' && this.detailValue.gianCach != null">
              <strong>{{ detailName.gianCach }}</strong>
              <p class="text-muted">
                {{ detailValue.gianCach }}
              </p>
              <hr>
            </div>

            <div v-if="detailValue.giaVe != '' && this.detailValue.giaVe != null">
              <strong>{{ detailName.giaVe }}</strong>
              <p class="text-muted">
                {{ detailValue.giaVe }}
              </p>
              <hr>
            </div>

            <div v-if="detailValue.giaTien != '' && this.detailValue.giaTien != null">
              <strong>{{ detailName.giaTien }}</strong>
              <p class="text-muted">
                {{ detailValue.giaTien }}
              </p>
              <hr>
            </div>

            <div v-if="detailValue.phamVi != '' && this.detailValue.phamVi != null">
              <strong>{{ detailName.phamVi }}</strong>
              <p class="text-muted">
                {{ detailValue.phamVi }}
              </p>
              <hr>
            </div>

            <div v-if="detailValue.web != '' && this.detailValue.web != null">
              <strong>{{ detailName.web }}</strong>
              <p class="text-muted">
                <a href="#" v-on:click="openNewTab(detailValue.web)">{{ detailValue.web }}</a>
              </p>
              <hr>
            </div>

            <div v-if="detailValue.congTy != '' && this.detailValue.congTy != null">
              <strong>{{ detailName.congTy }}</strong>
              <p class="text-muted">
                {{ detailValue.congTy }}
              </p>
              <hr>
            </div>

            <div v-if="detailValue.veVaoCong != '' && this.detailValue.veVaoCong != null">
              <strong>{{ detailName.veVaoCong }}</strong>
              <p class="text-muted">
                {{ detailValue.veVaoCong }}
              </p>
              <hr>
            </div>

            <!-- <div v-if="detailValue.gpsLong != ''  && this.detailValue.gpsLong != null">
                <strong>{{detailName.gpsLong}}</strong>
                <p class="text-muted">
                  {{detailValue.gpsLong}}
                </p>
                <hr>
            </div> -->

            <!-- <div v-if="detailValue.gpsLat != ''  && this.detailValue.gpsLat != null">
                <strong>{{detailName.gpsLat}}</strong>
                <p class="text-muted">
                  {{detailValue.gpsLat}}
                </p>
                <hr>
            </div> -->

            <!-- <div v-if="detailValue.stt != ''  && this.detailValue.stt != null">
                <strong>{{detailName.stt}}</strong>
                <p class="text-muted">
                  {{detailValue.stt}}
                </p>
                <hr>
            </div> -->



            <a href="#" class="btn btn-primary btn-block" v-on:click="hideModal()"><b>Đóng</b></a>
          </div>
        </div>
        <!-- /.card -->
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import { mapActions } from 'vuex'
import { listItemModel } from "@/models/listItem.model"
import { detailItemModel } from "@/models/detailItem.model"
import { mapMarkersModel } from "@/models/mapMarkers.model"
//--View--
import TheNavBar from "../dashboard/TheNavBar.vue"
import TheMap from "../maps/TheMap.vue"

export default {
  name: 'TheListPage',
  inheritAttrs: false,
  data() {
    return {
      id: null,
      menuNameCha: null,
      menuNameCon: null,
      nameList: null,
      listItem: Array,
      listMapItem:Array,
      detailName: detailItemModel.newModel(),
      detailValue: detailItemModel.newModel(),
      isCompleted: false
    }
  },
  components: {
    TheNavBar,
    TheMap
  },
  methods: {
    ...mapActions('readData', ['readData_WithMenuAppID', 'readData_WithTableId_DetailId']),


    //#region getData 
    async getTableData() {
      await this.readData_WithMenuAppID({ "actionCode": 10, "menuAppID": parseInt(this.id) }).then(res => {
        this.listItem = listItemModel.toListModel(res);
        this.nameList = res[0].groupName;
      });
      await this.mapDataHandler(this.listItem);
      if(this.listMapItem.length > 0) this.isCompleted = true;
    },
    async showDetail(item) {
      await this.readData_WithTableId_DetailId({ "actionCode": 3, "tableId": item.tableId, "detailId": item.detailId }).then(res => {
        this.detailName = res[0];
        this.detailValue = res[1];
        this.showModal();
      });
    },
    //#endregion getData

    //#region MapHandler
    async mapDataHandler(listItem){
      let templistMapItem = [];
      listItem.forEach((item) => {
        if(item.gpsLat != null && item.gpsLat != ''){
          let temp = mapMarkersModel.newModel();
          temp.detailId = item.detailId;
          temp.position.lat = parseFloat(item.gpsLat);
          temp.position.lng = parseFloat(item.gpsLong);
          temp.groupName = item.groupName;
          temp.ten = item.ten;
          temp.sdt = item.sdt;
          temp.diaChi = item.diaChi;
          templistMapItem.push(temp);
        }

      });
      this.listMapItem = templistMapItem;
    },
    async callMapMethod(){
      this.$refs.map.innerClick()
    },
    async focusMapEvent(lat,lng){
      await this.$refs.map.focusMarker(lat,lng);
    },
    //#endregion MapHandler

    //#region CommonFunction
    async openNewTab(url) {
      // const routeData = this.$router.resolve({name: 'routeName', query: {data: "someData"}});
      // window.open(routeData.href, '_blank');
      window.open(url, '_blank');
    },

    async showModal() {
      $('#detailModal').modal('show');
    },
    // function hide popup
    async hideModal() {
      $('#detailModal').modal('hide');
      //await this.getData()
    },
    async scrollToTop() {
      window.scrollTo(0,0);
    },
    //#endregion CommonFunction
  },
  async mounted() {
    this.id = this.$route.params.id;
    this.menuNameCon = this.$route.params.menuNameCon;
    await this.getTableData();
    await this.scrollToTop();
  }
}
</script>
<style></style>
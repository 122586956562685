
const newModel = () => {
    return {
        menuAppID: null,
        ten: "",
        link: null,
        logo: null,
        hinhAnh: null,
        menuCon: null,
        stt: null,
        tableId: null,
        loaiDiadiemId: null,
        phanloaiDiadiemId: null,
        sdt: null,
        type: null,
        menuName: "",
    }
}

const fromJson = (item) => {
    return {
        menuAppID: item.menuAppID,
        ten: item.ten,
        link: item.link,
        logo: item.logo,
        hinhAnh: item.hinhAnh,
        menuCon: item.menuCon,
        stt: item.stt,
        tableId: item.tableId,
        loaiDiadiemId: item.loaiDiadiemId,
        phanloaiDiadiemId: item.phanloaiDiadiemId,
        sdt: item.sdt,
        type: item.type,
        menuName: item.menuName,
    }
}

const toListModel = (items) =>{
    if(items.length > 0){
        let data = [];
        items.map((value, index) =>{
            data.push(fromJson(value));
        })
        return data??[];
    }
    return [];
}



export const menuItemModel = {
    newModel,
    toListModel
}

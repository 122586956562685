const newModel = () => {
    return {
        userName: '',
        password: '',
        rememberMe: false,

    }
}

const fromJson = (item) => {
    return {
        userName: item.userName,
        password: item.password,
        rememberMe: item.rememberMe,

    }
}

const toListModel = (items) =>{
    if(items.length > 0){
        let data = [];
        items.map((value, index) =>{
            data.push(fromJson(value));
        })
        return data??[];
    }
    return [];
}

export const userloginModel = {
    newModel,
    toListModel
}


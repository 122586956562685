import {positionModel} from './position.model';

const newModel = () => {
    return {
        //required
        detailId:null,
        position: positionModel.newModel(),

        //extensions
        groupName:null,
        ten:null,
        sdt:null,
        diaChi:null,
    }
}

const fromJson = (item) => {
    return {
        detailId: item.detailId,
        position: item.position,
        groupName: item.groupName,
        ten: item.ten,
        sdt:  item.sdt,
        diaChi:  item.diaChi,
    }
}

const toListModel = (items) =>{
    if(items.length > 0){
        let data = [];
        items.map((value, index) =>{
            data.push(fromJson(value));
        })
        return data??[];
    }
    return [];
}


export const mapMarkersModel = {
    newModel,
    fromJson,
    toListModel,
}

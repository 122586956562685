const newModel = () => {
    return {
        quanHuyenId: null,
        tenQuanHuyen: "",
        gioiThieu: "",
        tinhTpId: null,
        tenTinhTp: "",
        stt: null,
        }
}

const fromJson = (item) => {
    return {
        quanHuyenId: item.quanHuyenId,
        tenQuanHuyen: item.tenQuanHuyen,
        gioiThieu: item.gioiThieu,
        tinhTpId: item.tinhTpId,
        tenTinhTp: item.tenTinhTp,
        stt: item.stt,
    }
}

const toListModel = (items) =>{
    if(items.length > 0){
        let data = [];
        items.map((value, index) =>{
            data.push(fromJson(value));
        })
        return data??[];
    }
    return [];
}

export const districtModel = {
    newModel,
    toListModel
}


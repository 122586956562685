<template>
    <!-- Navbar -->
    <nav class="main-header navbar navbar-expand-md navbar-light navbar-white">
        <div class="container">
            <a href="/" class="navbar-brand">
                <img src="../../assets/logo_tpcl.png" alt="Cao Lãnh SmartCity" class="brand-image img-circle elevation-3"
                    style="opacity: .8;">
                <span class="brand-text font-weight-light">Cao Lãnh SmartCity</span>
            </a>

            <button class="navbar-toggler order-1" type="button" data-toggle="collapse" data-target="#navbarCollapse"
                aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse order-3" id="navbarCollapse">

            </div>

            <!-- Right navbar links -->
            <!-- <ul class="order-1 order-md-3 navbar-nav navbar-no-expand ml-auto">
                <li class="nav-item" v-if="isLogin == false">
                    <a class="nav-link" href="/TheRegisterPage" role="button">
                        Đăng ký
                    </a>
                </li>
                <li class="nav-item" v-if="isLogin == false">
                    <a class="nav-link" v-on:click="showModal()" role="button">
                        Đăng nhập
                    </a>
                </li>


                <li class="nav-item dropdown" v-if="isLogin == true">
                    <a class="nav-link" data-toggle="dropdown" href="#">
                        <i class="far fa-user"></i> Xin chào, {{ displayUserName }}
                    </a>
                    <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                        <a href="#" class="dropdown-item">
                            <i class="fas fa-circle-info mr-2"></i> Thông tin tài khoản
                        </a>
                        <a href="#" class="dropdown-item" v-on:click="onLogout()">
                            <i class="fas fa-right-from-bracket mr-2"></i> Đăng xuất
                        </a>
                    </div>
                </li>
            </ul> -->


        </div>
    </nav>
    <!-- /.navbar -->


    <!--POP UP LOGIN-->
    <div class="modal fade" id="loginModal" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="card-primary">
                    <div class="card-header">
                        <h3 class="card-title">Đăng nhập</h3>
                        <button type="button" class="close" aria-label="Close" @click="hideModal()">
                            <span aria-hidden="true">x</span>
                        </button>
                    </div>
                    <div class="card-body box-profile">
                        <div class="row mb-2">
                            <label for="" class="col-sm-4 col-form-label" style="text-align:right">Tên đăng nhập<span
                                    class="text-danger">(*)</span></label>
                            <div class="col-sm-8">
                                <input class="form-control" placeholder="Nhập tên đăng nhập" v-model="model.userName"
                                    @blur="validate()" :class="{ 'is-invalid': errors.userName }" />
                            </div>
                        </div>
                        <div class="text-danger text-right mb-2" style="margin-top: -13 !important;" v-if="errors.userName">
                            {{ errors.userName }}</div>

                        <div class="row mb-2">
                            <label for="" class="col-sm-4 col-form-label" style="text-align:right">Mật khẩu<span
                                    class="text-danger">(*)</span></label>
                            <div class="col-sm-8">
                                <input class="form-control" placeholder="Nhập mật khẩu" v-model="model.password"
                                    @blur="validate()" :class="{ 'is-invalid': errors.password }" />
                            </div>
                        </div>
                        <div class="text-danger text-right mb-2" v-if="errors.password">{{ errors.password }}</div>
                        <div class="row">
                            <div class="col-8">
                                <div class="icheck-primary">
                                    <input type="checkbox" id="remember" v-model="model.rememberMe">
                                    <label for="remember">
                                        Ghi nhớ đăng nhập
                                    </label>
                                </div>
                            </div>
                            <div class="col-4">
                                <button type="submit" class="btn btn-primary btn-block" @click="onSubmit();">Đăng nhập</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import $ from 'jquery'
import { mapActions } from 'vuex'
import { userloginModel } from '@/models/user/user_login.model'
import { router } from 'vue-router'
export default {
    name: 'TheNavBar',
    props: {
        // msg: String
    },
    components: {
    },
    data() {
        return {
            model: userloginModel.newModel(),
            errors: {
                loginName: '',
                password: '',
            },

            isLogin: false,
            displayUserName: '',
        }
    },
    methods: {
        ...mapActions('user', ['login', 'checkLogin', 'logout', 'getDisplayUserName']),
        async showModal() {
            $('#loginModal').modal('show');
        },
        // function hide popup
        async hideModal() {
            $('#loginModal').modal('hide');
            //await this.getData()
        },

        async checkInfo() {
            this.isLogin = await this.checkLogin();
            if(this.isLogin) await this.getDisplayName();
        },

        async getDisplayName(){
            this.displayUserName = await this.getDisplayUserName();
        },

        onLogout() {
            this.logout();
            this.$router.go(this.$router.currentRoute)
        },

        //#region Submit
        async onSubmit() {
            this.submitted = true;
            if (await this.validate()) {
                let value = await this.login({ "user": this.model });
                if (value == true) {
                    this.hideModal();
                    this.$router.go(this.$router.currentRoute)
                }
            }
            this.submitted = false;
        },
        //#endregion Submit

        //#region Validation
        async validate() {
            let isvalid = true
            // set up errors
            this.errors = {
                userName: '',
                password: '',
            }
            // check null model
            if (!this.model.userName) {
                this.errors.userName = "Nhập tên đăng nhập";
                isvalid = false;
            }
            if (!this.model.password) {
                this.errors.password = "Nhập mật khẩu";
                isvalid = false;
            }

            return isvalid
        },
        //#endregion Validation
    },
    async mounted() {
        await this.checkInfo();
    }
}
</script>

<style></style>
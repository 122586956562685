const newModel = () => {
    return {
        tableId: null,
        detailId: null,
        ten: null,
        sdt: null,
        tuyenDuong: null,
        diaChi: null,
        gioiThieu: null,
        khungGio: null,
        loTrinh: null,
        gioMoCua: null,
        gianCach: null,
        giaVe: null,
        giaTien: null,
        phamVi: null,
        web: null,
        congTy: null,
        veVaoCong: null,
        hinhAnh: null,
        gpsLong: null,
        gpsLat: null,
        stt: null,
    }
}

const fromJson = (item) => {
    return {
        tableId: item.tableId,
        detailId: item.detailId,
        ten:  item.ten,
        sdt:  item.sdt,
        tuyenDuong:  item.tuyenDuong,
        diaChi:  item.diaChi,
        gioiThieu:  item.gioiThieu,
        khungGio:  item.khungGio,
        loTrinh:  item.loTrinh,
        gioMoCua:  item.gioMoCua,
        gianCach:  item.gianCach,
        giaVe:  item.giaVe,
        giaTien:  item.giaTien,
        phamVi:  item.phamVi,
        web:  item.web,
        congTy:  item.congTy,
        veVaoCong:  item.veVaoCong,
        hinhAnh:  item.hinhAnh,
        gpsLong:  item.gpsLong,
        gpsLat:   item.gpsLat,
        stt:  item.stt,
    }
}

const toListModel = (items) =>{
    if(items.length > 0){
        let data = [];
        items.map((value, index) =>{
            data.push(fromJson(value));
        })
        return data??[];
    }
    return [];
}

export const detailItemModel = {
    newModel,
    toListModel
}

<template>
    <div style="cursor: pointer;" @click="openX(menuItemInfor.link)" class="customs-hover">
        <div>
            <div style="width: 88px; height: 88px;" class="box-center">
                <div class="center-img">
                    <img class="img-fluid" :src="menuItemInfor.hinhAnh" alt="Hình ảnh"> 
                </div>
            </div>

            <h5 class="text-center title-s title-hover" >{{ menuItemInfor.ten }}</h5> 
        </div>
    </div>
</template>

<script>

import { menuItemModel } from "@/models/menuItem.model"
export default {
    name: 'TheCard_v1',
    props: {
        menuItemInfor: menuItemModel.newModel(),
    },
    methods: {
        async openX(url) {
            console.log(1);
            window.open(url, '_blank');
        }
    },
}
</script>

<style>

.center-img{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 30%;
    -ms-transform: translate(-20%, -50%);
    transform: translate(-20%, -50%);
    /* default value  top: 50%;left: 50%;translate(-50%, -50%);transform: translate(-50%, -50%); */
}

.box-center{
    position: relative; 
    border:1px solid #dae0e6;
    margin-left:auto;
    margin-right: auto;
    border-radius: 50%;
    box-shadow: 0px 5px 20px 9px #dae0e6;
}

.title-s{
    margin-top:15px;
    font-size: 18px;
    color: #0741F7;
}

.customs-hover:hover .title-hover{
  color: #B2DC07;
}



</style>
import { createApp } from 'vue'
import App from './App.vue'
import router from "./router";
import RouterView  from "vue-router";
import {store} from '@/store'
import {dom, library} from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
library.add(fas);
library.add(far);
dom.watch()
//import jQuery from 'jquery';
import '../node_modules/admin-lte/dist/css/adminlte.min.css'
import '../node_modules/admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css'
import "../node_modules/admin-lte/plugins/select2-bootstrap4-theme/select2-bootstrap4.min.css"
import "../node_modules/admin-lte/plugins/select2/css/select2.min.css"
//import '../node_modules/vue3-loading-overlay/dist/vue3-loading-overlay.css';

import "../node_modules/admin-lte/plugins/jquery/jquery.min.js"
import "../node_modules/admin-lte/plugins/bootstrap/js/bootstrap.bundle.min.js"
import "../node_modules/admin-lte/plugins/overlayScrollbars/js/jquery.overlayScrollbars.min.js"
import "../node_modules/admin-lte/plugins/select2/js/select2.full.min.js"
import "../node_modules/admin-lte/dist/js/adminlte.js"



const app = createApp(App);
//app.use(jQuery)
app.use(router);
app.use(store);
app.use(RouterView);
app.mount('#app')

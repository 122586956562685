<template>
    <!-- <div class="card card-widget widget-user-2">
        <div class="widget-user-header bg-barcolor" style="height: 100px;">
            <div class="row">
                <div class="col-lg-4">
                    <div>
                        <img :src="menuItemInfor.hinhAnh" alt="menu-avatar" class=" img-fluid"
                            style="  display: block;margin-left: auto;margin-right: auto; max-width: 100%;max-height: 100%;">
                    </div>
                </div>
                <div class="col-lg-8" style="text-align: center !important;">
                    <h4>{{ menuItemInfor.ten }}</h4>
                </div>
            </div>
        </div>
        <div class="card-footer p-0 mb-3" style="background: transparent;height: 300px;overflow-y: scroll;">
            <ul class="nav flex-column" v-for="item in listItem" :key="item.menuAppID">
                <li class="nav-item">
                    <router-link :to="{ name: 'TheListPage', params: { menuNameCha: menuNameCha,  id:item.menuAppID, menuNameCon:item.menuName}}" class="nav-link">
                        <img :src=item.hinhAnh alt="Icon" class=" img-fluid" style="height: 50px;width: 50px; margin-left: 20px;">
                        <h5 class="float-right">{{ item.ten }}</h5>
                    </router-link>
                </li>
            </ul>
        </div>
    </div> -->
    <div class="card bg-light d-flex flex-fill">
        <!-- <div class="card-header text-muted border-bottom-0">
                Digital Strategist
            </div> -->
        <div class="pt-2 pb-2 bg-barcolor" >
            <div class="row" >
                <div class="col-4 text-center" style="padding-left: 0px;">
                    <img :src="menuItemInfor.hinhAnh" alt="user-avatar" class="img-fluid"  style="  display: block;margin-left: auto;margin-right: auto; max-width: 60px;max-height: 60px;">
                </div>
                <div class="col-8  text-center"  style="display:flex;align-items: center;justify-content: center;">
                        <h5 ><strong>{{ menuItemInfor.ten }}</strong></h5>
                </div>
            </div>
        </div>
        <div class="card-footer" style="background: transparent;height: 300px;overflow-y: scroll;">
            <ul class="nav flex-column" v-for="item in listItem" :key="item.menuAppID">
                <li class="nav-item">
                    <router-link :to="{ name: 'TheListPage', params: { menuNameCha: menuNameCha,  id:item.menuAppID, menuNameCon:item.menuName}}" class="nav-link">
                        <div class="row">
                            <div class="col-3">
                                <img :src=item.hinhAnh alt="Icon" class=" img-fluid" style="height: 50px;width: 50px;">
                            </div>
                            <div class="col-9"  style="display:flex;align-items: center;justify-content: right;">
                                <h6 style="font-size: 18px;">{{ item.ten }}</h6>
                            </div>

                            
                        </div>

                    </router-link>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import { menuItemModel } from "@/models/menuItem.model"
export default {
    name: 'TheCard_v2',
    props: {
        menuItemInfor: menuItemModel.newModel(),
    },
    data() {
        return {
            listItem: [],
            menuNameCha: "",
        }
    },
    methods: {
        ...mapActions('readData', ['readData_WithMenuAppID']),
        async getTableData() {

            await this.readData_WithMenuAppID({ "actionCode": 9, "menuAppID": this.menuItemInfor.menuAppID }).then(res => {

                this.listItem = menuItemModel.toListModel(res);
                this.listItem.forEach((item) => {
                    item.menuName = String(item.menuName).replaceAll("_", "-");

                });
                //console.log(this.listItem)
            });
        },
        async buttonClickHandler(name) {
            this.$parent.callSub(name)
        }
    },
    async mounted() {
        this.menuNameCha = String(this.menuItemInfor.menuName).replaceAll("_", "-");
        await this.getTableData();

    },
}
</script>

<style>
.bg-barcolor {
    /* background-color: #095EF9; */
    background-color: #418fee;
}

.bg-color {
    background-color: #E2E8E8;
}
</style>
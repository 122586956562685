const newModel = () => {
    return {
        lng: 0.0,
        lat: 0.0,
    }
}

const fromJson = (item) => {
    return {
        lng: item.lng,
        lat: item.lat,
    }
}

export const positionModel = {
    newModel,
    fromJson
}


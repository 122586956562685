/* eslint-disable */
import {countryUnitService} from "@/services/countryUnit.services";
const actions = {
    async readData_Province({
        dispatch
    }) {
        return await countryUnitService.readData_Province();
    },
    async readData_District({
        dispatch
    }, {tinhTpId}) {
        return await countryUnitService.readData_District(tinhTpId);
    },
    async readData_Wards({
        dispatch
    }, {quanHuyenId}) {
        return await countryUnitService.readData_Wards(quanHuyenId);
    },

    async readData_Hamlet({
        dispatch
    }, {phuongXaId}) {
        return await countryUnitService.readData_Hamlet(phuongXaId);
    },

    async readData_SubQuarter({
        dispatch
    }, {khomApId}) {
        return await countryUnitService.readData_SubQuarter(khomApId);
    }


}
export const countryUnit = {
    namespaced: true,
    actions

};

import Vuex from 'vuex'

import {authentication}  from './modules/authentication';
 import {alert}  from './modules/alert';
// import {header} from './modules/header';
import {readData} from './modules/readData';
import {countryUnit} from './modules/countryUnit';
import {user} from './modules/user';

const debug = process.env.NODE_ENV !== 'production'
export const store = new Vuex.Store({
  modules: {
    authentication,
    user,
    alert,
    readData,
    countryUnit,
  }, strict: debug
});

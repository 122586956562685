const newModel = () => {
    return {
        tinhTpId: null,
        tenTinhTp: "",
        stt: null,
    }
}

const fromJson = (item) => {
    return {
        tinhTpId: item.tinhTpId,
        tenTinhTp: item.tenTinhTp,
        stt: item.stt,
    }
}

const toListModel = (items) =>{
    if(items.length > 0){
        let data = [];
        items.map((value, index) =>{
            data.push(fromJson(value));
        })
        return data??[];
    }
    return [];
}

export const provinceModel = {
    newModel,
    toListModel
}


<template>
    <div class="wrapper">
        <TheNavBar></TheNavBar>

        <div class="content-wrapper">
            <!-- <div class="content-header">
                <div class="container">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                        </div>
                        <div class="col-sm-6">
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="content">
                <div class="container ">
                    <div class="row ">
                        <div class="card card-solid mt-1" style=" margin-bottom: 6px;">
                            <div class="card-body" style="padding: 10px;">
                                <div class="row" style="height:10px"></div>
                                <div class="row pl-3 pr-3 pt-1">
                                    <div class="col" v-for="item in listItem_1" :key="item.menuAppID">
                                        <TheCard_v1 :menuItemInfor="item"></TheCard_v1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class=" card card-solid">
                            <div class="row pl-3 pr-3 pt-1">
                                <div class="col-lg-4 mt-2" v-for="item in listItem_2" :key="item.menuAppID">
                                    <TheCard_v2 :menuItemInfor="item"></TheCard_v2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { mapActions } from 'vuex'
import TheNavBar from "../dashboard/TheNavBar.vue"
import { menuItemModel } from "@/models/menuItem.model"
//--View--
import TheCard_v1 from "../items/TheCard_v1.vue"
import TheCard_v2 from "../items/TheCard_v2.vue"
export default {
    name: 'TheMainScreen',
    data() {
        return {
            model: menuItemModel.newModel(),
            listItem_1: Array,
            listItem_2: Array

        }
    },

    components: {
        TheNavBar,
        TheCard_v1,
        TheCard_v2,
    },
    methods: {
        ...mapActions('authentication', ['checkTokens_NoLogin', 'getNoTokens']),
        ...mapActions('readData', ['readData_NoTable']),
        async getData() {
            await this.readData_NoTable({ "actionCode": 7 }).then(res2 => {
                this.listItem_1 = res2;
            });
            await this.readData_NoTable({ "actionCode": 8 }).then(res => {
                this.listItem_2 = res;
            });
        },
    },
    async mounted() {
        await this.getData()
    }
}
</script>
<style>
.bg-color {
    background-color: #E2E8E8;
}
</style>